<template>
  <div class="container">
    <div class="public-content">
      <div class="login-container">
        <div class="left-item">
          <div class="page-title">欢迎登录{{ websiteInfo.SiteName }}</div>
          <div class="sbu-title">畅享多重管道收益</div>
          <div class="img img-cover"><img src="../../assets/pc-images/login-img.png" /></div>
        </div>
        <div class="right-item">
          <div class="login-type-item">
            <div class="type-item" :class="pageType == 1 ? 'active' : ''" @click="pageType = 1">登录</div>
            <div v-if="showRegister" class="type-item" :class="pageType == 2 ? 'active' : ''" @click="pageType = 2">注册</div>
          </div>
          <template v-if="pageType == 1">
            <div class="input-item">
              <div class="input"><input v-model="loginFrom.UserName" type="text" placeholder="用户名/手机号" /></div>
            </div>
            <div class="input-item">
              <div class="input"><input v-model="loginFrom.Pwd" :type="showLoginPwd ? 'text' : 'password'" placeholder="请输入账号密码" /></div>
              <div class="eye-item img-cover" @click="showLoginPwd = !showLoginPwd">
                <img v-if="showLoginPwd" src="../../assets/pc-images/eye-icon.png" />
                <img v-else src="../../assets/pc-images/eye-close-icon.png" />
              </div>
            </div>
            <div class="forget-item">
              <router-link :to="{ path: '/forget' }">忘记密码？</router-link>
            </div>
            <div class="input-item">
              <div class="input"><input v-model="loginFrom.CaptchaValue" type="text" placeholder="请输入验证码" /></div>
              <div class="code-img img-cover" @click="handleGetLoginCaptcha">
                <img :src="loginCaptchaPath" />
              </div>
            </div>
            <div class="btn-item" @click="loginSubmit">
              <van-loading v-if="loginSubmiting" color="#ffffff" />
              <span v-else>登录</span>
            </div>
          </template>
          <template v-else>
            <div class="input-item">
              <div class="input"><input v-model="registerFrom.UserName" type="text" placeholder="请输入用户名" /></div>
            </div>
            <div class="input-item">
              <div class="input"><input v-model="registerFrom.Phone" type="text" placeholder="请输入手机号" /></div>
            </div>
            <div v-if="showZcYzm" class="input-item">
              <div class="input"><input v-model="registerFrom.CaptchaValue" type="text" placeholder="请输入验证码" /></div>
              <div class="code-img img-cover" @click="handleGetRegisterCaptcha">
                <img :src="registerCaptchaPath" />
              </div>
            </div>
            <div v-if="showZcYzm" class="input-item">
              <div class="input"><input v-model="registerFrom.ZcYqm" type="text" placeholder="请输入短信验证码" /></div>
              <div class="send-code" :class="time > 0 ? 'not' : ''" @click="getMsg">{{ time > 0 ? `${time}s` : '发送验证码' }}</div>
            </div>
            <div class="input-item">
              <div class="input"><input v-model="registerFrom.Pwd" :type="showRegisterPwd ? 'text' : 'password'" placeholder="请输入密码" /></div>
              <div class="eye-item img-cover" @click="showRegisterPwd = !showRegisterPwd">
                <img v-if="showRegisterPwd" src="../../assets/pc-images/eye-icon.png" />
                <img v-else src="../../assets/pc-images/eye-close-icon.png" />
              </div>
            </div>
            <div class="input-item">
              <div class="input"><input v-model="registerFrom.SurePwd" :type="showRegisterSurePwd ? 'text' : 'password'" placeholder="请再次输入密码" /></div>
              <div class="eye-item img-cover" @click="showRegisterSurePwd = !showRegisterSurePwd">
                <img v-if="showRegisterSurePwd" src="../../assets/pc-images/eye-icon.png" />
                <img v-else src="../../assets/pc-images/eye-close-icon.png" />
              </div>
            </div>
            <div v-if="showYqm" class="input-item">
              <div class="input"><input v-model="registerFrom.Yqm" type="text" placeholder="请输入邀请码" /></div>
            </div>
            <div class="btn-item public-btn" @click="registerSubmit">
              <van-loading v-if="submiting" color="#ffffff" />
              <span v-else>确定</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations, mapActions, mapState } from 'vuex'
 
export default {
  name: "Login",
  components: {
  },
  data() {
    return {
      pageType: 1, // '1: 登录, 2: 注册'
      loginSubmiting: false,
      showRegister: false,
      loginCaptchaPath: '',
      loginFrom: {
        UserName: '',
        Pwd: '',
        CaptchaValue: '',
      },
      showLoginPwd: false,
      registerSubmiting: false,
      showYqm: false,
      showZcYzm: false,
      registerCaptchaPath: '',
      timer: null,
      time: 0,
      registerFrom: {
        UserName: '',
        Phone: '',
        CaptchaValue: '',
        ZcYqm: '',
        Pwd: '',
        SurePwd: '',
        Yqm: '',
      },
      showRegisterPwd: false,
      showRegisterSurePwd: false
    }
  },
  watch: {
    pageType(val) {
      if (val == 1) {
        this.handleGetLoginCaptcha()
      }
      if (val == 2 && this.showZcYzm) {
        this.handleGetRegisterCaptcha()
      }
    }
  },
  computed: {
    ...mapState(['websiteInfo'])
  },
  mounted () {
    this.handleGetSetting()
    this.handleGetLoginCaptcha()
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    ...mapActions(['GetUserInfo']),
    async handleGetSetting() {
      var res = await this.$API.getSets.post({
        Key: 'is_register,is_yqm,is_zc_yzm'
      })
      this.showRegister = res.is_register == 1
      this.showYqm = res.is_yqm == 1
      this.showZcYzm = res.is_zc_yzm == 1
    },
    handleGetLoginCaptcha() {
      this.loginCaptchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async loginSubmit() {
      const { loginFrom } = this
      if (!loginFrom.UserName) {
        this.$toast('请输入您的手机号/用户名')
        return
      }
      if (!loginFrom.Pwd) {
        this.$toast('请输入您的密码')
        return
      }
      if (!loginFrom.CaptchaValue) {
        this.$toast('请输入验证码')
        return
      }
      this.loginSubmiting = true
      var res = await this.$API.login.post(loginFrom)
      this.loginSubmiting = false
      if (res.error == 0) {
        this.SET_TOKEN(res.info)
        var user = await this.GetUserInfo()
        if (user.error == 0) {
          this.$router.push({path: '/'})
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: user.info,
            confirmButtonText: '确定'
          })
          this.handleGetLoginCaptcha()
        }
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
        this.handleGetLoginCaptcha()
      }
    },

    handleGetRegisterCaptcha() {
      this.registerCaptchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async getMsg() {
      const { registerFrom, time, timer } = this
      if (time > 0 || timer != null) {
        return
      }
      if (!registerFrom.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!registerFrom.CaptchaValue) {
        this.$toast('请输入图形验证码')
        return
      }
      var res = await this.$API.sendMsg.post({
        Phone: registerFrom.Phone,
        CaptchaValue: registerFrom.CaptchaValue,
      })
      if (res.error == 0) {
        this.setTimer()
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
        this.registerCaptchaPath()
      }
    },
    setTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let t = 60
      this.time = t
      this.timer = setInterval(() => {
        t--
        this.time = t
        if (t <= 0) {
          clearInterval(this.timer)
        }
      }, 1000);
    },
    async registerSubmit() {
      const { registerFrom, showYqm, showZcYzm } = this
      if (!registerFrom.UserName) {
        this.$toast('请输入您的手机号/用户名')
        return
      }
      if (!registerFrom.Phone) {
        this.$toast('请输入您的手机号')
        return
      }
      if (showZcYzm && !registerFrom.ZcYqm) {
        this.$toast('请输入短信验证码')
        return
      }
      if (!registerFrom.Pwd) {
        this.$toast('请输入您的密码')
        return
      }
      if (!registerFrom.SurePwd) {
        this.$toast('请输入确认密码')
        return
      }
      if (registerFrom.Pwd != registerFrom.SurePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      if (!showYqm || !registerFrom.Yqm) {
        delete registerFrom.Yqm
      }
      this.registerSubmiting = true
      var res = await this.$API.register.post(registerFrom)
      this.submiting = false
      if (res.error == 0) {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '去登录'
        }).then(() => {
          this.pageType = 1
        })
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.login-container {
  padding: 0 40px 0 52px;
  min-height: calc(100vh - 66px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-item {
    width: 560px;
    height: 542px;
    padding-top: 60px;
    .page-title {
      font-size: 32px;
      font-weight: bold;
      color: #3d3d42;
      line-height: 46px;
    }
    .sbu-title {
      line-height: 22px;
      margin-top: 6px;
      color: #9fafc1;
    }
    .img {
      width: 560px;
      height: 428px;
      margin-top: 12px;
    }
  }
  .right-item {
    width: 420px;
    padding: 60px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, .4);
    .login-type-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 48px;
      .type-item {
        cursor: pointer;
        flex: 1;
        height: 52px;
        text-align: center;
        line-height: 34px;
        border-bottom: 2px solid #e9edf5;
        font-weight: bold;
        font-size: 24px;
        color: #3d3d42;
        &.active {
          color: #6a5ffe;
          border-color: #6a5ffe;
        }
      }
    }
    .input-item {
      border: 1px solid #e9edf5;
      border-radius: 6px;
      height: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      margin-bottom: 20px;
      .input {
        flex: 1;
        height: 40px;
        padding: 0 20px;
        input {
          color: #3d3d42;
        }
      }
      .eye-item {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
      .code-img {
        width: 120px;
        height: 42px;
        cursor: pointer;
      }
      .send-code {
        font-size: 14px;
        color: #6a5ffe;
        margin-right: 20px;
        &.not {
          color: #999;
        }
      }
    }
    .forget-item {
      margin-top: -18px;
      line-height: 22px;
      text-align: right;
      margin-bottom: 20px;
      a {
        color: #6a5ffe;
        font-size: 16px;
      }
    }
    .btn-item {
      cursor: pointer;
      width: 100%;
      height: 44px;
      border-radius: 6px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      color: #fff;
      text-align: center;
      line-height: 44px;
      font-size: 14px;
      font-weight: bold;
      margin-top: 60px;
    }
  }
}
</style>
